








































import { get_custom_near_site } from "@/api";
import { customNearSiteType } from "@/api/api.types";
import { Notif } from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";

type positionType =
  customNearSiteType["data"]["_embedded"]["nearestSite"][0] & {
    position: { lat: number; lng: number };
  };
@Component({ name: "nearby-site" })
export default class NearbySite extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string;

  @Prop({ required: true }) readonly building!: {
    name: string;
    position: { lat: number; lng: number };
  };

  /* Variables */

  data = [] as positionType[];

  currentPost = { lat: 0, lng: 0 };

  infoWindow = {
    position: { lat: 0, lng: 0 },
    name: "",
    address: "",
    distance: "",
    id: "",
    IsOpen: false,
    options: { pixelOffset: { width: 0, height: -35 } },
  };

  /* Computed */

  /* Methods */

  async fetchData() {
    try {
      const resp = await get_custom_near_site({
        customer_uuid: this.value,
        limit: "10",
      });
      this.data = resp.data._embedded.nearestSite.map((t) => {
        return {
          ...t,
          position: {
            lat: parseFloat(t.latitude),
            lng: parseFloat(t.longitude),
          },
        };
      });
      this.currentPost = this.data[0].position;
      let bounds = new google.maps.LatLngBounds(); // eslint-disable-line
      this.data.forEach((t) => {
        bounds.extend({
          lat: t.position.lat,
          lng: t.position.lng,
        });
      });
    } catch (error) {
      Notif.notif_warning(error.response.data.detail);
    }
  }

  getInfoWindows(detail: positionType) {
    this.infoWindow = {
      position: detail.position,
      name: detail.name,
      address: detail.address,
      distance: parseFloat(detail.distance).toFixed(2),
      id: "track",
      IsOpen: true,
      options: this.infoWindow.options,
    };
  }

  /* Life-cycle Methods */

  mounted() {
    this.fetchData();
  }
}
